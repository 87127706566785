@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.swiper-full {
  width: 100%;
  background: #032515;
  --swiper-navigation-color: white;
  --swiper-pagination-color: white;
}

.container {
  max-width: 100%;
  padding: 0;
}

.word {
  margin: auto;
  color: white;
  font: 700 normal 1.8em 'tahoma';
  text-shadow: 5px 2px #222324, 2px 4px #222324, 3px 5px #222324;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20%);
  }
}

.contact-form-enter {
  animation: slideDown 0.5s forwards;
}

.contact-form-exit {
  animation: slideUp 0.5s forwards;
}
